import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Home from "./Home";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  [theme.breakpoints.down("sm")]: {},
}));
function App() {
  return (
    // <StyledBox paddingY={6} paddingX={2}>
     <StyledBox>
      <Home />
    </StyledBox>
  );
}

export default App;
