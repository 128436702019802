import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  mode: "dark",
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#fff",
    },
    darker: {
      main: "#fff",
    },
    text: {
      primary: "#fff",
    },
    action: {
      disabledBackground: "#fff",
      disabled: "#fff",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    body1: {
      fontSize: "1.6rem",
      color: "#FFFFFF",
      lineHeight: 1.2,
    },
    body2: {
      fontSize: ".9rem",
      color: "#FFFFFF",
    },
    body3: {
      fontSize: "1rem",
      color: "#FFFFFF",
    },
    allVariants: {
      color: "#fff",
    },
    h5: {
      fontSize: 24,
      fontWeight: 500,
    },
    h6: {
      fontSize: "1.6rem",
      color: "#FFFFFF",
      fontWeight: 1000,
    },
    h7: {
      fontSize: "2rem",
      fontWeight: "bold",
      cursor: "pointer",
    },
    h8: {
      fontSize: "2.6rem",
      fontWeight: "bold",
      lineHeight: 1.1,
      cursor: "pointer",
    },
    h9: {
      fontSize: "1.6rem",
      fontWeight: "bold",
      lineHeight: 1.1,
      cursor: "pointer",
    },
    h10: {
      fontSize: "3rem",
      fontWeight: "bold",
      lineHeight: 1.1,
      cursor: "pointer",
    },
    button1:{
        color: "#FFFFFF",
       fontSize: "16px",
       fontWeight: 'bold',
       fontFamily: "Roboto-Bold, Roboto",
    },
     button2:{
        color: "#FFFFFF",
       fontSize: "13px",
       fontWeight: 'bold',
       fontFamily: "Roboto-Bold, Roboto",
       lineHeight: '30px',
          },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "12px 24px",
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "8px",
          fontWeight: "bold",
          fontSize: "22px",
          paddingLeft: "20px",
          paddingRight: "20px",
          // minWidth: 229,
          backgroundColor: "#E02020",
          color: "#fff",
          cursor: "pointer",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#E02220",
          },
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: "#fff",
        },
        containedPrimary: {
          color: "#fff",
        },
      },
    },
  },
});

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
