import { Button, Typography } from "@mui/material";
import { Box, height, textAlign } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import QRCode from "qrcode.react";
import Logo from "../images/logo.png";
import Phone1 from "../images/Phone1.png";
import Phone2 from "../images/Phone2.png";
import Phone from "../images/Phone.png";

import name from "../images/name.png";

export default function Main() {
  const goToAddress = (address) => {
    window.open(address, "_blank");
  };
  const isSmallScreen = useMediaQuery("(max-width: 696px)");
  const isLargeScreen = useMediaQuery("(max-width: 1600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1366px)");
  return (
    <Box
      sx={{
        maxwidth: isSmallScreen ? "100%" : "960px",
        mx: { xs: "20px", sm: "160px" },
        display: "flex",
        justifyContent: "space-between",
        flexDirection: isSmallScreen ? "column" : "row",
      
      }}
    >
      <Box>
        {isSmallScreen ? (
         <br></br>
        ) : (
          <Box
            sx={{
              height: { xs: "24px",md:"110px" },
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              mt: { md: "120px", xl: "200px" },
            }}
          >
            <img
              style={{
                width: "100px",
                height: "100px",
                marginRight: "16px",
              }}
              alt=""
              src={Logo}
            />
  
          </Box>
          
        )}
          {isSmallScreen ? (
          <></>
        ) : (
      <Box
          sx={{
            mt: { xs: "10px", sm: "32px" },
            mr: { xl: "140px", xs: "0" },
            textAlign: { xs: "center", sm: "left" },
          }}
          >
              <Typography
           variant="h10"
          >
             <img
              style={{
                width: "200px",
                height: "60px",
                marginRight: "16px",
              }}
              alt=""
              src={name}
            />
          </Typography>
           
  
          </Box>
          
        )}
        <Box
          sx={{
            mt: { xs: "40px", sm: "32px" },
            mr: { xl: "140px", xs: "0" },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography
            sx={{ whiteSpace: { sm: "nowrap" } }}
            variant={
              isSmallScreen
                ? "h9"
                : isMediumScreen
                ? "h6"
                : isLargeScreen
                ? "h7"
                : "h8"
            }
          >
            All format support
          </Typography>
        </Box>
     
        <Box
          sx={{
            mt: { xs: "24px", md: "12px", xl: "24px" },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography
            variant={
              isSmallScreen ? "body2" : isMediumScreen ? "body3" : "body1"
            }
          >
          Playing movies videos&music ,all in MU Player
          </Typography>
        </Box>
        <Box
          sx={{
            mt: { xs: "2rem", md: "1rem", xl: "2rem" },
            mx: {xs:"auto",sm:"initial"},
           textAlign: { xs: "center", sm: "left" },
          }}
        >
            <Button
            onClick={() => {
              goToAddress("https://play.google.com/store/apps/details?id=com.video.downloader.vidtube");
            }}
            sx={{
              width:{ xl: "278px", md: "188px", xs: "210px" },
              height:{ xl: "55px", md: "47px", xs: "40px" },
              textAlign: { xs: "center", sm: "left" },
              background: "linear-gradient(90deg, #01C6E5 0%, #05EE66 100%) #19CC94",
              boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.2)",
              borderRadius: '8px',

            }}
          >
            <Typography
            sx={{ whiteSpace: { sm: "nowrap" } }}
            variant={
              isSmallScreen
                ? "button2"
                : isMediumScreen
                ? "button1"
                : isLargeScreen
                ? "button1"
                : "button1"
            }
            
          >
          Start Free Trial
          </Typography>
         
            
          </Button>
   
        </Box>

      </Box>
              {isSmallScreen?(
                  <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: isSmallScreen ? "column" : "row",
          mt: isSmallScreen ? "60px" : "80px",
          mb:{xs:"40px",sm:"initial"}
        }}
      >
        <img
          style={{
            width: isSmallScreen ? "160px" : "16.8vw",
            height: isSmallScreen ? "340px" : "34vw",
            marginRight: isSmallScreen ? "0px" : "40px",
            marginTop:isSmallScreen?0:"20px",
            display: "inline-block",
          }}
             
          alt=""
          src={Phone1}
        />
 
        <img
          style={{
            width: isSmallScreen ? "160px" : "16.8vw",
            height: isSmallScreen ? "340px" : "34vw",
            display: "inline-block",
           
            marginTop: isSmallScreen ? "90px" : "-40px",
          }}
          alt=""
          src={Phone2}
        />
      </Box>
              ):(
           <Box
            sx={{
              height: { xs: "24px",md:"110px" },
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              mt: { md: "120px", xl: "200px" },
            }}
          >
               <img
          style={{
            width: '44vw',
            height:"39vw",
            display: "inline-block",
            marginTop: "17vw",
          }}
          alt=""
          src={Phone}
        />
      
        </Box>
        )}
    
     
    </Box>
  );
}
